<template>
  <b-card-code>
    <div v-if="$can('create','Classes')" class="custom-search d-flex">
      <router-link :to="{name:'class-add'}">
        <b-button variant="outline-primary">
          {{ $t('Add new') }}
        </b-button>
      </router-link>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>
    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="classes"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'name'">
          {{ props.row.name }}
        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'versions'">
          <template>
          <ul>
            <li v-for="version in props.row.versions" v-bind:key="version.name" >{{version.name}}</li>
          </ul>
        </template>
        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'shifts'">
          <template>
          <ul>
            <li v-for="shift in props.row.shifts" v-bind:key="shift.name">{{shift.name}}</li>
          </ul>
        </template>
        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'student_groups'">
          <template>
          <ul>
            <li v-for="group in props.row.student_groups" v-bind:key="group.name" >{{group.name}}</li>
          </ul>
        </template>

        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'wings'">
         
           <template>
          <ul>
            <li v-for="wing in props.row.wings" v-bind:key="wing.name">{{wing.name}}</li>
          </ul>
        </template>
        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Classes')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button v-if="$can('update','Classes')"  @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['5','10','20','30','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'Classes',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,BFormCheckbox,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      required,
      pageLength: 5,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL#',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Versions',
          field: 'versions',
        },
        {
          label: 'Shifts',
          field: 'shifts',
        },
        {
          label: 'Student Groups',
          field: 'student_groups',
        },
        {
          label: 'Wings',
          field: 'wings',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      columns10: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Versions',
          field: 'Versions',
        },
        {
          label: 'Shifts',
          field: 'Shift',
        },
        {
          label: 'Student Groups',
          field: 'StudentGroup',
        },
        {
          label: 'Wings',
          field: 'Wings',
        },
        {
          label: 'Status',
          field: 'Status',
        }
      ],
    }
  },
  methods:{
    selectedRow(row){
      this.$router.push({name:'class-update',params:{id:row.id}});
    },
    async statusChange(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/classes/status/change${id}`).then((response)=>{
            this.$store.dispatch('GET_ALL_CLASSES');
            this.$toaster.success( response.data.message);
          }).catch((error)=>{
            this.$store.dispatch('GET_ALL_CLASSES');
            this.$toaster.error(error.response.data.message);
          });
        }else{
          this.$store.dispatch('GET_ALL_CLASSES');
        }
      })
    },
     pdfGenerate() {
      let title = "Current Classes";
      let clm = ['Name','Versions', 'Shift','StudentGroup' ,'Wings','Status'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'class_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['classes']),
    getData() {
      return this.classes.map(item => ({
        Name: item.name ? item.name : '',
        Versions: item.versions ? item.versions.name : '',
        Shift: item.shifts ? item.shifts.name : '',
        StudentGroup: item.student_groups ? item.student_groups.name : '',
        Wings: item.wings ? item.wings.name : '',
        Status: item.is_active ? 'Active' : 'Inactive'
      }));
    },
  },
  created() {
    this.$store.dispatch('GET_ALL_CLASSES');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>